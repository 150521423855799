<template>
  <b-row>
    <!-- use component -->
    <index-form-modal ref="indexFormModal" @reload="getNotificationTransaction" />
    <!-- end use component -->
    <b-col sm="12">
      <!-- <TableDataWidget
        ref="tableUserInfo"
        title='ประวัติการแจ้งเตือน'
        url='/api/notification-data/index'
        @clickNew="$refs.indexFormModal.show()"
        @clickEdit="$refs.indexFormModal.show({...$event})"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/notification-data/index',$event)"
        :columns="columns"
        newAble
        viewAble
        editAble
        deleteAble
        btnText="เพิ่มข้อความ"
      /> -->
      <button-widget text="เพิ่มแจ้งเตือน" variant="primary" block class="mb-50" @click="$refs.indexFormModal.show()" />
      <table-local-v-1-widget
        ref="tableUserInfo"
        title="ประวัติการแจ้งเตือน"
        :columns="columns"
        :items="items"
        :loading="loading"
      >
        <template #custom_status="{row}">
          <b-badge :variant="checkIfSuccessSend(row).variant">
            {{ checkIfSuccessSend(row).label }}
          </b-badge>
        </template>
        <template #custom_action="{row}">
          <b-button
            v-if="checkIfSuccessSend(row).variant === ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.top="'ยกเลิกส่ง Notification'"
            size="sm"
            variant="danger"
            class="btn-icon"
            style="padding: 0.2rem;"
            @click="clickCancelSendPendingNotification(row)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <span v-else />
        </template>
      </table-local-v-1-widget>
      <!-- <pre>{{ items[0] }}</pre> -->
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MutewallIndex',
  components: {
    indexFormModal: () => import('./components/index/IndexFormModal.vue'),
  },
  data() {
    return {
      columns: [
        {
          label: 'วันเวลาที่ส่งแจ้งเตือน',
          field: 'date_setting',
          formatFn: (val, row) => {
            let dateSent = row.created_at

            if (val) {
              dateSent = val
            }

            return this.$date(dateSent).format('MM-DD-YYYY HH:mm:ss')
          },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'หัวข้อ',
          field: 'title',
        },
        {
          label: 'ข้อความ',
          field: 'body',
        },
        {
          label: 'สถานะ',
          field: 'custom_status',
          type: 'custom',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: '',
          field: 'custom_action',
          type: 'custom',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      items: [],
      loading: false,
    }
  },

  mounted() {
    this.getNotificationTransaction()
  },

  methods: {
    async getNotificationTransaction() {
      this.loading = true
      const resp = await this.api.get('/api/notification-data/index').catch(() => null)
      if (resp) {
        this.items = [...resp]
      }
      this.loading = false
    },
    checkIfSuccessSend(row) {
      const actionType = `${row.action_type}`

      if (actionType.includes('cancel')) {
        return { label: 'ยกเลิก', variant: 'warning' }
      }

      if (row?.status === true) {
        return { label: 'ส่งแล้ว', variant: 'success' }
      }

      return { label: 'รอเวลาส่ง', variant: '' }
    },
    async clickCancelSendPendingNotification(row) {
      const isConfirm = await this.gCheckConfirmV1('ยกเลิกส่ง Notification')

      if (!isConfirm) return

      // console.log('clickCancelSendPendingNotification', row)
      this.gOpenPageLoading()
      const resp = await this.api.postV2('api/admin/notification-data/cancel', { id: row.id }, this)
      if (resp?.code === 405) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.data.message, 'warning')
      }
      this.gClosePageLoading()
      await this.getNotificationTransaction()
      // console.log('clickCancelSendPendingNotification', resp)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
